<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true"
      :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VFormGizi">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pengkajian Gizi</h6>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_line">


                    <div class="card" v-if="row.ap_usia <= 13">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Anthropometri</h6>
                      </div>
                      <div class="card-body mt-2">
                        <div class="row">
                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>BB<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input @input="countBBIdeal()" :formatter="number" v-model="row.arankg_bb"
                                  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg</span>
                                </div>
                              </div>
                              <VValidate name="BB" v-model="row.arankg_bb" :rules="mrValidation.arankg_bb" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>TB/Panjang Badan<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input @input="countBBIdeal()" :formatter="number" v-model="row.arankg_tb"
                                  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span>
                                </div>
                              </div>
                              <VValidate name="TB/Panjang Badan" v-model="row.arankg_tb"
                                :rules="mrValidation.arankg_tb" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankg_bb_ideal" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg</span>
                                </div>
                              </div>
                              <VValidate name="BB Ideal" v-model="row.arankg_bb_ideal"
                                :rules="mrValidation.arankg_bb_ideal" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>% BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankg_bb_ideal_pecentage"
                                  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">%</span>
                                </div>
                              </div>
                              <VValidate name="% BB Ideal" v-model="row.arankg_bb_ideal_pecentage"
                                :rules="mrValidation.arankg_bb_ideal_pecentage" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>Z Score<small class="txt_mandatory">*</small></label>
                              <v-select placeholder="-- Plih Salah Satu --"
                                @input="selectStatusGizi($event,Config.mr.zScore)" v-model="row.arankg_zscore"
                                :options="Config.mr.zScore" label="text" :clearable="true" :reduce="v=>v.value">
                              </v-select>
                            </div>
                          </div>

                          <div class="col-md-4 col-lg-2">
                            <div class="form-group">
                              <label>Status Gizi<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <input type="text" v-model="row.arankg_status_gizi" class="form-control" disable
                                  readonly>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card" v-else>
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Anthropometri</h6>
                      </div>
                      <div class="card-body mt-2">
                        <div class="row">
                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>BB<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input @input="countBBIdeal()" :formatter="number" v-model="row.arankg_bb"
                                  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg</span>
                                </div>
                              </div>
                              <VValidate name="BB" v-model="row.arankg_bb" :rules="mrValidation.arankg_bb" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>TB/Panjang Badan<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input @input="countBBIdeal()" :formatter="number" v-model="row.arankg_tb"
                                  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span>
                                </div>
                              </div>
                              <VValidate name="TB/Panjang Badan" v-model="row.arankg_tb"
                                :rules="mrValidation.arankg_tb" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankg_bb_ideal" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg</span>
                                </div>
                              </div>
                              <VValidate name="BB Ideal" v-model="row.arankg_bb_ideal"
                                :rules="mrValidation.arankg_bb_ideal" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>% BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankg_bb_ideal_pecentage"
                                  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">%</span>
                                </div>
                              </div>
                              <VValidate name="% BB Ideal" v-model="row.arankg_bb_ideal_pecentage"
                                :rules="mrValidation.arankg_bb_ideal_pecentage" />
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>IMT<small class="txt_mandatory">*</small></label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankg_imt" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg/m2</span>
                                </div>
                              </div>
                              <VValidate name="BB Ideal" v-model="row.arankg_imt"
                                :rules="mrValidation.arankg_imt" />
                            </div>
                          </div>

                          <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                              <label>Status Gizi <small class="txt_mandatory">*</small></label>
                              <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankg_status_gizi"
                                :options="Config.mr.statusGiziRanap" label="text" :clearable="true"
                                :reduce="v=>v.value">
                              </v-select>
                              <VValidate name="Status Gizi" v-model="row.arankg_status_gizi"
                                :rules="mrValidation.arankg_status_gizi" />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-md-12">
                  <div class="wrap_line">
                    <h3> <span>Kebiasaan Makan</span></h3>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="head_panel_red mt-2">
                          <div class="row">
                            <div class="col-md-7">
                              <h3>ALERGI MAKANAN </h3>
                            </div>
                            <div class="col-md-5">
                              <div class="form-group mb-0 mt-1">
                                <b-form-radio-group :options="Config.mr.yesNoOpt" v-model="row.arankg_alergi_makan" />

                                <VValidate name="Alergi Makanan" v-model="row.arankg_alergi_makan"
                                  :rules="mrValidation.arankg_alergi_makan" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-5" v-if="row.arankg_alergi_makan == 'Y'">
                        <div class="form-group">
                          <label>Makanan<small class="txt_mandatory">*</small></label>
                          <b-form-input :formatter="normalText" v-model="row.arankg_alergi_makan_text" type="text"
                            class="form-control" />
                          <VValidate name="Makanan" v-model="row.arankg_alergi_makan_text"
                            :rules="mrValidation.arankg_alergi_makan_text" />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Pola makan<small class="txt_mandatory">*</small></label>

                          <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankg_pola_makan"
                            :options="Config.mr.ranapGiziPolaMakan" label="text" :clearable="true" :reduce="v=>v.value">
                          </v-select>
                          <VValidate name="Pola makan" v-model="row.arankg_pola_makan"
                            :rules="mrValidation.arankg_pola_makan" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mt-3 mb-2">
                          <div class="form-group">
                            <label>Cara Pemberian<small class="txt_mandatory">*</small></label>
                            <b-form-radio-group :options="Config.mr.ranapGiziCaraPemberian"
                              v-model="row.arankg_cara_pemberian" />
                            <VValidate name="Cara Pemberian" v-model="row.arankg_cara_pemberian"
                              :rules="mrValidation.arankg_cara_pemberian" />
                          </div>

                          <div class="form-group" v-if="row.arankg_cara_pemberian">
                            <label>Cara Pemberian Lewat {{row.arankg_cara_pemberian}}<small
                                class="txt_mandatory">*</small></label>
                            <b-form-radio-group
                              :options="row.arankg_cara_pemberian == 'Mulut' ? Config.mr.ranapGiziIntervensiLewatMulut : Config.mr.ranapGiziIntervensiLewatPipa"
                              v-model="row.arankg_cara_pemberian_detail" />
                            <VValidate name="Cara Pemberian Detail" v-model="row.arankg_cara_pemberian_detail"
                              :rules="mrValidation.arankg_cara_pemberian_detail" />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Cara Pemberian Additional di RANAP-->

                  <div class="col-12">
                    <div class="card mb-0">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                      </div>
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-md-12">
                            <h6>Hasil Pemeriksaan Lab</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                                <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in (dataHasilLab||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                    <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i
                                          class="icon-file-download"></i></a>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!(dataHasilLab||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-md-12 mt-3">
                            <h6>Hasil Pemeriksaan Radiologi</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                                <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in (dataHasilRadiologi||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                    <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i
                                          class="icon-file-download"></i></a>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!(dataHasilRadiologi||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-md-12">
                            <h6>Hasil Pemeriksaan Lab Dokter</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                                <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in (dataHasilLabDokter||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                    <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i
                                          class="icon-file-download"></i></a>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!(dataHasilLabDokter||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-md-12 mt-3">
                            <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                                <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in (dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                    <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i
                                          class="icon-file-download"></i></a>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!(dataHasilRadiologiDokter||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-8 col-lg-6">
                    <div class="wrap_line">
                      <h3><span>Fisik/Klinis</span></h3>
                      <div>
                        <b-form-radio-group class="custom-block" :options="Config.mr.yesNoOptKeluhan"
                          v-model="row.arankg_fisik_keluhan" />

                        <VValidate name="Fisik/Klinis" v-model="row.arankg_fisik_keluhan"
                          :rules="mrValidation.arankg_fisik_keluhan" />
                      </div>
                      <div class="row mt-1" v-if="row.arankg_fisik_keluhan == 'Y'">
                        <div class="col-md-12 mb-2">
                          <div class="form-check form-check-block mb-2">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arankg_is_diare"
                              class="form-check-input-styled">
                              Diare
                            </b-form-checkbox>
                            <div class="ml-2" v-if="row.arankg_is_diare == 'Y'">
                              <b-form-input :formatter="normalText" v-model="row.arankg_diare_text" type="text"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="form-check form-check-block mb-2">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arankg_is_muntah"
                              class="form-check-input-styled">
                              Muntah
                            </b-form-checkbox>
                            <div class="ml-2" v-if="row.arankg_is_muntah == 'Y'">
                              <b-form-input :formatter="normalText" v-model="row.arankg_muntah_text" type="text"
                                class="form-control" />
                            </div>
                          </div>
                          <div class="form-check form-check-block mb-2">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arankg_is_lainnya"
                              class="form-check-input-styled">
                              Lain-Lain
                            </b-form-checkbox>
                            <div class="ml-2" v-if="row.arankg_is_lainnya == 'Y'">
                              <b-form-input :formatter="normalText" v-model="row.arankg_lainnya_text" type="text"
                                class="form-control" />
                            </div>
                          </div>

                          <div class="form-check form-check-block">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arankg_is_gangguan_menelan"
                              class="form-check-input-styled">
                              Gangguan Menelan
                            </b-form-checkbox>
                          </div>
                          <div class="form-check form-check-block">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arankg_is_konstipasi"
                              class="form-check-input-styled">
                              Konstipasi
                            </b-form-checkbox>
                          </div>
                          <div class="form-check form-check-block">
                            <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arankg_is_gangguan_mengunyah"
                              class="form-check-input-styled">
                              Gangguan Mengunyah
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Cara Pemberian-->

                  <div class="col-md-12">
                    <div class="wrap_line">
                      <h3> <span>Riwayat Personal</span></h3>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Riwayat Personal<small class="txt_mandatory">*</small></label>
                            <textarea v-model="row.arankg_riwayat_personal" rows="5" cols="3" class="form-control"
                              placeholder="" spellcheck="false"></textarea>
                            <VValidate name="Riwayat Personal" v-model="row.arankg_riwayat_personal"
                              :rules="mrValidation.arankg_riwayat_personal" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="wrap_line">
                      <h3> <span>Problem, Etiologi, Sign & Symptom</span></h3>
                      <div class="row gx-3 gy-2">
                        <div class="col-lg-6">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th width="50%">Problem</th>
                                <th width="10%">Hapus</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.arankg_problem||[])" :key="k">
                                <td>
                                  <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                    <label for="">Problem</label>
                                    <v-select @input="selectIntervensi($event,mICDGiziProblem,'arankg_problem',k)"
                                      placeholder="-- Plih Salah Satu --" v-model="v.id" :options="mICDGiziProblem"
                                      label="text" :clearable="true" :reduce="v=>v.value">

                                      <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.down :title="option.text">{{ option.text }}</span>
                                      </template>

                                    </v-select>
                                    <!--
                                    <VValidate :name="'Problem #'+(k+1)"
                                    v-model="v.id"
                                    :rules="{required: 1}" />
                                    -->
                                  </div>
                                  <div class="form-group">
                                    <label>Problem</label>
                                    <textarea v-model="v.text" rows="3" class="form-control"></textarea>
                                    <VValidate :name="'Problem Ket #'+(k+1)" v-model="v.text"
                                      :rules="{required: 1, min: 3, max: 128}" />
                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arankg_problem',k)" data-toggle="tooltip"
                                    data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center" colspan="99">
                                  <a href="javascript:;" @click="addIntervensi('arankg_problem')"
                                    class="btn mb-2  alpha-blue">
                                    <i class="icon-plus2 mr-2"></i>
                                    Tambah
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="col-lg-6">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th width="50%">Etiology/Penyebab</th>
                                <th width="10%">Hapus</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.arankg_etiologi||[])" :key="k">
                                <td>
                                  <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                    <label for="">Etiologi</label>
                                    <v-select @input="selectIntervensi($event,mICDGiziEtimologi,'arankg_etiologi',k)"
                                      placeholder="-- Plih Salah Satu --" v-model="v.id" :options="mICDGiziEtimologi"
                                      label="text" :clearable="true" :reduce="v=>v.value">

                                      <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.down :title="option.text">{{ option.text }}</span>
                                      </template>

                                    </v-select>
                                    <!--                                                                        
                                    <VValidate :name="'Etiologi #'+(k+1)"
                                    v-model="v.id"
                                    :rules="{required: 1}" />
                                    -->
                                  </div>
                                  <div class="form-group">
                                    <label>Etiologi</label>
                                    <textarea v-model="v.text" rows="3" class="form-control"></textarea>
                                    <VValidate :name="'Etiologi Ket #'+(k+1)" v-model="v.text"
                                      :rules="{required: 1, min: 3, max: 128}" />
                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arankg_etiologi',k)" data-toggle="tooltip"
                                    data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center" colspan="99">
                                  <a href="javascript:;" @click="addIntervensi('arankg_etiologi')"
                                    class="btn mb-2  alpha-blue">
                                    <i class="icon-plus2 mr-2"></i>
                                    Tambah
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="col-lg-6">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th width="50%">Tanda dan Gejala</th>
                                <th width="10%">Hapus</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.arankg_sign||[])" :key="k">
                                <td>
                                  <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                    <label for="">Tanda Dan Gejala</label>
                                    <v-select @input="selectIntervensi($event,mICDGiziSign,'arankg_sign',k)"
                                      placeholder="-- Plih Salah Satu --" v-model="v.id" :options="mICDGiziSign"
                                      label="text" :clearable="true" :reduce="v=>v.value">

                                      <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.down :title="option.text">{{ option.text }}</span>
                                      </template>

                                    </v-select>
                                    <!--                                                                        
                                    <VValidate :name="'Tanda Dan Gejala #'+(k+1)"
                                    v-model="v.id"
                                    :rules="{required: 1}" />
                                    -->
                                  </div>
                                  <div class="form-group">
                                    <label>Tanda Dan Gejala</label>
                                    <textarea v-model="v.text" rows="3" class="form-control"></textarea>

                                    <VValidate :name="'Tanda Dan Gejala Ket #'+(k+1)" v-model="v.text"
                                      :rules="{required: 1, min: 3, max: 128}" />

                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arankg_sign',k)" data-toggle="tooltip"
                                    data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center" colspan="99">
                                  <a href="javascript:;" @click="addIntervensi('arankg_sign')"
                                    class="btn mb-2  alpha-blue">
                                    <i class="icon-plus2 mr-2"></i>
                                    Tambah
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="wrap_line">
                      <h3> <span>Diagnosa</span></h3>
                      <div class="row gx-3 gy-2">
                        <div class="col-lg-6">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th width="50%">Diagnosis Gizi</th>
                                <th width="10%">Hapus</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.arankg_diagnosa_gizi||[])" :key="k">
                                <td>
                                  <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                    <label for="">Diagnosis Gizi</label>
                                    <v-select @input="selectIntervensi($event,mICDGizi,'arankg_diagnosa_gizi',k)"
                                      placeholder="-- Plih Salah Satu --" v-model="v.id" :options="mICDGizi"
                                      label="text" :clearable="true" :reduce="v=>v.value">

                                      <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.down :title="option.text">{{ option.text }}</span>
                                      </template>

                                    </v-select>
                                    <!--
                                    <VValidate :name="'Diagnosis Gizi #'+(k+1)"
                                    v-model="v.id"
                                    :rules="{required: 1}" />
                                    -->
                                  </div>
                                  <div class="form-group">
                                    <label>Diagnosis Gizi</label>
                                    <textarea v-model="v.text" rows="3" class="form-control"></textarea>
                                    <VValidate :name="'Diagnosis Gizi Ket #'+(k+1)" v-model="v.text"
                                      :rules="{required: 1, min: 3, max: 128}" />
                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arankg_diagnosa_gizi',k)" data-toggle="tooltip"
                                    data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center" colspan="99">
                                  <a href="javascript:;" @click="addIntervensi('arankg_diagnosa_gizi')"
                                    class="btn mb-2  alpha-blue">
                                    <i class="icon-plus2 mr-2"></i>
                                    Tambah
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="col-lg-6">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th width="50%">Intervensi</th>
                                <th width="10%">Hapus</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.arankg_intervensi||[])" :key="k">
                                <td>
                                  <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                    <label for="">Intervensi Gizi </label>
                                    <v-select @input="selectIntervensi($event,mICDGiziTindakan,'arankg_intervensi',k)"
                                      placeholder="-- Plih Salah Satu --" v-model="v.id" :options="mICDGiziTindakan"
                                      label="text" :clearable="true" :reduce="v=>v.value">
                                      <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.down :title="option.text">{{ option.text }}</span>
                                      </template>                                      
                                    </v-select>

                                    <!--
                                    <VValidate :name="'Intervensi Gizi #'+(k+1)"
                                    v-model="v.id"
                                    :rules="{required: 1}" />
                                    -->
                                  </div>
                                  <div class="form-group">
                                    <label>Intervensi Gizi </label>
                                    <textarea v-model="v.text" rows="3" class="form-control"></textarea>
                                    <VValidate :name="'Intervensi Gizi Ket #'+(k+1)" v-model="v.text"
                                      :rules="{required: 1, min: 3, max: 128}" />
                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arankg_intervensi',k)" data-toggle="tooltip"
                                    data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center" colspan="99">
                                  <a href="javascript:;" @click="addIntervensi('arankg_intervensi')"
                                    class="btn mb-2  alpha-blue">
                                    <i class="icon-plus2 mr-2"></i>
                                    Tambah
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="col-lg-6">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th width="50%">Tujuan Intervensi</th>
                                <th width="10%">Hapus</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.arankg_tujuan_intervensi||[])" :key="k">
                                <td>
                                  <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                    <label for="">Tujuan Intervensi</label>
                                    <v-select
                                      @input="selectIntervensi($event,mICDGiziTujuan,'arankg_tujuan_intervensi',k)"
                                      placeholder="-- Plih Salah Satu --" v-model="v.id" :options="mICDGiziTujuan"
                                      label="text" :clearable="true" :reduce="v=>v.value">
                                      <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.down :title="option.text">{{ option.text }}</span>
                                      </template>
                                    </v-select>
                                    <!--
                                    <VValidate :name="'Tujuan Intervensi #'+(k+1)"
                                    v-model="v.id"
                                    :rules="{required: 1}" />
                                    -->
                                  </div>
                                  <div class="form-group">
                                    <label>Tujuan Intervensi</label>
                                    <textarea v-model="v.text" rows="3" class="form-control"></textarea>
                                    <VValidate :name="'Tujuan Intervensi Ket #'+(k+1)" v-model="v.text"
                                      :rules="{required: 1, min: 3, max: 128}" />
                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arankg_tujuan_intervensi',k)" data-toggle="tooltip"
                                    data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center" colspan="99">
                                  <a href="javascript:;" @click="addIntervensi('arankg_tujuan_intervensi')"
                                    class="btn mb-2  alpha-blue">
                                    <i class="icon-plus2 mr-2"></i>
                                    Tambah
                                  </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="wrap_line">
                      <h3><span>Intervensi Makanan</span></h3>
                      <div class="row">
                        <div class="col-md-4 mb-2">
                          <div class="form-group">
                            <label>Cara pemberian <small class="txt_mandatory">*</small></label>
                            <div>
                              <div class="form-check form-check-inline mb-2">
                                <b-form-checkbox value="Y" unchecked-value="N"
                                  v-model="row.arankg_cara_pemberian_lewat_mulut" class="form-check-input-styled">
                                  Lewat Mulut
                                </b-form-checkbox>
                              </div>
                              <div class="ml-3" v-if="row.arankg_cara_pemberian_lewat_mulut == 'Y'">
                                <b-form-group>
                                  <b-form-checkbox-group v-model="row.arankg_cara_pemberian_lewat_mulut_text"
                                    :options="Config.mr.ranapGiziIntervensiLewatMulut"></b-form-checkbox-group>
                                </b-form-group>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="col-md-6 mb-2">
                          <div class="form-group">
                            <label></label>
                            <div>
                              <div>
                                <div class="form-check form-check-inline mb-2">
                                  <b-form-checkbox value="Y" unchecked-value="N"
                                    v-model="row.arankg_cara_pemberian_lewat_pipa" class="form-check-input-styled">
                                    Lewat Pipa
                                  </b-form-checkbox>
                                </div>
                                <div class="ml-3" v-if="row.arankg_cara_pemberian_lewat_pipa == 'Y'">
                                  <b-form-group>
                                    <b-form-checkbox-group v-model="row.arankg_cara_pemberian_lewat_pipa_text"
                                      :options="Config.mr.ranapGiziIntervensiLewatPipa"></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="wrap_line">
                      <h3><span>Implementasi</span></h3>
                      <div class="row">
                        <div class="col-md-12 mb-2">
                          <b-form-group>
                            <b-form-checkbox-group class="checkbox-block" v-model="row.arankg_implementasi"
                              :options="Config.mr.ranapGiziImplementasi"></b-form-checkbox-group>
                            <div class="ml-2" v-if="(row.arankg_implementasi||[]).indexOf('Lainnya') != -1">
                              <b-form-input :formatter="normalText" v-model="row.arankg_implementasi_lainnya"
                                type="text" class="form-control" />

                              <VValidate name="Implementasi" v-model="row.arankg_implementasi_lainnya"
                                :rules="{required: 1, min: 3, max: 128}" />
                            </div>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="text-right">
                  <button type="button" @click="back()" class="btn btn-light mr-1">Back</button>
                  <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
              </div>
            </div>
            <!-- /dashboard content -->
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'

  export default {
    extends: GlobalVue,
    components:{ 
        PatientInfo
    },
    
    data() {
      return {
        rowReg: {},
        dataHasilRadiologi: [],
        dataHasilLab: [],
        dataHasilRadiologiDokter: [],
        dataHasilLabDokter: [],

        mICDGizi: [],
        mICDGiziTindakan: [],
        mICDGiziTujuan: [],

        mICDGiziEtimologi: [],
        mICDGiziSign: [],
        mICDGiziProblem: [],
        
        patientData: {},
        loading: {
          patientInfo: false,
        }
      }
    },
    methods: {
      calculateAgeInMonths(birthdate) {
        const birth = new Date(birthdate)
        const current = new Date()
        let yearsDifference = current.getFullYear() - birth.getFullYear()
        let monthsDifference = current.getMonth() - birth.getMonth()
        if (monthsDifference < 0) {
          yearsDifference--
          monthsDifference += 12
        }
        const totalMonths = yearsDifference * 12 + monthsDifference
        return totalMonths - 1
      },

      countBBIdeal(){
        let bbIdeal = 0
        // arankg_bb
        // arankg_tb

        // rumus baru
        let usia = this.row.ap_usia

        if(usia < 1){
          let bulan = this.calculateAgeInMonths(this.row.ap_dob)
          bbIdeal = (bulan + 9) / 2
        }else if(usia >= 1 && usia < 7){
          bbIdeal = 2 * (usia) + 8
        }else if(usia >= 7 && usia < 14){
          bbIdeal = (7 * (usia) - 5 ) / 2
        }else{
          bbIdeal = (this.row.arankg_tb - 100) * 0.9
        }


        this.row.arankg_bb_ideal = bbIdeal
        this.row.arankg_bb_ideal = this.row.arankg_bb_ideal.toFixed(2)

        this.row.arankg_bb_ideal_pecentage = this.row.arankg_bb/bbIdeal * (100/100)
        this.row.arankg_bb_ideal_pecentage = this.row.arankg_bb_ideal_pecentage * 100
        this.row.arankg_bb_ideal_pecentage = this.row.arankg_bb_ideal_pecentage.toFixed(2)

        this.row.arankg_imt = this.row.arankg_bb / ((this.row.arankg_tb/100) * (this.row.arankg_tb/100))
        this.row.arankg_imt = this.row.arankg_imt.toFixed(2)
      },
      selectStatusGizi(value,master){
        if(value){
          let index = (master || []).findIndex(x => x.value == value)          
          if (index !== -1) {
            this.row.arankg_status_gizi = master[index]['desc']
          }
        }else{
          this.row.arankg_status_gizi = null
        }
      },
      selectIntervensi(value,master,field,k){
        if (value) {
          let index = (master || []).findIndex(x => x.value == value)          
          if (index !== -1) {
            this.row[field][k]['name'] = master[index]['text']
            this.row[field][k]['text'] = master[index]['description']
          }
        }else{
          this.row[field][k]['name'] = null
        }
      },
      deleteIntervensi(field,k){
        this.row[field].splice(k,1)
      },
      addIntervensi(field){
        this.row[field].push({
          id: null,
          name: null,
          text: null
        })
      },
      back() {
        this.$router.back()
      },
      toValidate(val) {
        return {
          ...val
        }
      },
      apiGet(params = {}, page = 1) {
        if (!this.pageSlug || !this.$route.query.regId) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.$route.params.rmNo ? this.modulePage + '/' + this.$route.params.pageSlug + '/' + this
          .$route.params.rmNo : this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.pageNow = page
          this.last_page = this.data.last_page
          
          if(this.row.arankg_tb && this.row.arankg_bb){
            this.countBBIdeal()
          }

          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })

        // master
        if (this.$route.params.rmNo) {
          Gen.apiRest(
            "/get/" + url + '?master=1', {
              params: Object.assign({
                page: page
              }, paramsQuery, params.query || {})
            }
          ).then(res => {
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })
          })
        }

      },
      
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.query.regId
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },

      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
          let index = (master || []).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },

      autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        data.aranrm_last_step = this.row.aranrm_last_step

        if (!data.isEdit) {
          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          )
        }
      }, 1000),
      

      
      doSubmit() {

        if (this.row.aranrm_is_active == 'N') {
          return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          })
        }

        this.$refs['VFormGizi'].validate().then(success => {
          
          if(this.row.arankg_cara_pemberian_lewat_mulut != 'Y' && this.row.arankg_cara_pemberian_lewat_pipa != 'Y'){
            return this.$swal({
              icon: 'error',
              title: 'Intervensi Makanan Belum Diisi'
            })
          }

          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                  
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }

                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }
          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.row
                data.type = 'submit-data'

                this.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + this.modulePage, {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false

                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.$router.push({
                      name: 'RanapPasienPerawatanGizi'
                    }).catch(() => {})
                  })
                })
              }
            })
          }
        })
      }
    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
    watch: {
      row: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
  }

</script>